#commentAlertModal {
  .MuiBox-root {
    height: fit-content !important;
  }
  .MuiInputBase-inputMultiline {
    overflow: auto !important;
  }
  #modal-modal-title {
    text-align: start;
  }
}
